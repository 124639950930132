import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { formatCurrency } from '../../../hooks/store/actionConsts.js'
import { recordInvoiceDetail} from '../../../hooks/store/actions/financeActions.js'
import { StyleSheet, css } from 'aphrodite'


const styles = StyleSheet.create({
    newContactInputRow: {
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: "10px",
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormInputDate: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    newContactFormLabel: {
        fontSize: "small",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactHeaderContainer: {
        //Unresponsive marginTop
        marginTop: "11px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: "24px",
        display: "block",
        width: "100%",
        marginBottom: "15px",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353"
    },
    newContactFilterHeaders: {
        fontSize: "20px",
        fontWeight: "900",
        fontFamily: "'Open Sans', sans-serif",
        color: "#535353",
        width: "150px"
    },
    newContactFilterCol: {
        maxWidth: "180px"
    },
    newContactFilterCheckbox: {
        marginTop: "10px"
    },  
    newContactHeader: {
        display: "inline-block",
        fontSize: "18px",
        width: "auto"
    },
    newContactCustomHr: {
        display: "inline-block",
        borderTop: '1px solid #949494',
        width: "100%",
        margin: "5px"
    }
})

class InvoiceDetailRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.onChange = this.onChange.bind(this);
    }

    onChange = (e) => {
        var rowNum = Number(e.target.id.substring(5));
        var value = parseFloat(e.target.value).toFixed(2);
        this.setState({ [e.target.id]: e.target.value });
        this.props.recordInvoiceDetail(rowNum, value);
    }

    filterLineItems(list) {
        let lineItems = [];
        list.map((listItem, index) => {
            if (listItem.price > 0) {
                lineItems.push(listItem);
            }
        });
        return lineItems; 
    }

    render() {
        var nonZeroItems = this.props.details; 
        return (
            nonZeroItems.map(
                (j, r) =>
                    <span key={r}>
                        <Row className={r % 2 === 0 ? "grid-row row-white invoiceDetailItem" : "grid-row row-gray invoiceDetailItem"} >
                            <Col sm={2}>{j.item.itemName}</Col>
                            <Col sm={2}>{j.item.itemDescription}</Col>
                            {!this.props.editing && (<Col className="priceColInvoice" sm={1}>${j.price ? formatCurrency(j.price.toFixed(2)) : 0.00}</Col>)}
                            {this.props.editing && (<Col sm={1}><Form.Control className={css(styles.newContactFormInput)} id={"price"+String(r)} onChange={this.onChange} defaultValue={j.price.toFixed(2)} value={this.state["price" + String(r)]} /></Col>)}
                            <Col className="totalColInvoice" sm={1}>${j.total ? formatCurrency(j.total.toFixed(2)) : 0}</Col>
                            <Col className="billColInvoice" sm={1}>{j.isClientBillable ? <span className="billableItem">[Billable]<span className="tooltiptextBillable">This item is billed to Client</span></span> : <div className="nonBillableItem">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="tooltiptextBillable">This item is NOT billed to Client</span></div>}</Col>
                        </Row>
                    </span>
            )
        );
    }

    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user        
    }
}

export default connect(mapStateToProps, {recordInvoiceDetail})(InvoiceDetailRow);
