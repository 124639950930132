import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { formatCurrency } from '../../../hooks/store/actionConsts.js'

class BillingPlanDetailRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            this.props.details.map(
                (j, r) =>
                    <span key={j.billingPlanDetailID}>
                        <Row className={r % 2 === 0 ? "grid-row row-white" : "grid-row row-gray"} >
                            <Col sm={1}>{j.billingPlanDetailType}</Col>
                            <Col sm={1}>{j.totalInvoiceCharge ? formatCurrency(j.totalInvoiceCharge.toFixed(2)): 0.00}</Col>
                            <Col sm={1}>{j.totalInvoiceCharge_BeforeTax ? formatCurrency(j.totalInvoiceCharge_BeforeTax.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.affiliateRate ? formatCurrency(j.affiliateRate.toFixed(2)) : 0}</Col>
                            <Col sm={1}>${j.aCBillingFee ? formatCurrency(j.aCBillingFee.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.merchantFee ? formatCurrency(j.merchantFee.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.affiliateTax ? formatCurrency(j.affiliateTax.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.affiliateNetPayment ? formatCurrency(j.affiliateNetPayment.toFixed(2)) : 0.00}</Col>                                             
                            <Col sm={1}>{j.affiliateNetIncome ? formatCurrency(j.affiliateNetIncome.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.affiliateNetIncome_BeforeTax ? formatCurrency(j.affiliateNetIncome_BeforeTax.toFixed(2)) : 0.00}</Col>
                            <Col sm={1}>{j.totalTax ? formatCurrency(j.totalTax.toFixed(2)) : 0.00}</Col>
                        </Row>                        
                    </span>
            )
        );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user        
    }
}

export default connect(mapStateToProps, {})(BillingPlanDetailRow);
