import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from 'react-bootstrap/FormLabel';
import Form from 'react-bootstrap/Form';
import Select from 'react-styled-select'
import { StyleSheet, css } from 'aphrodite'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { showAddressForm, updateAddress } from '../../../hooks/store/actions/contactActions.js';


const styles = StyleSheet.create({

    addressFormInput: {
        backgroundColor: '#e5e5e5',
        border: '1px solid #949494',
        marginTop: '5px',
        borderRadius: "0px",
        height: '32px',
        fontSize: '14px',
        fontFamily: "'Open Sans', sans-serif"
    },
    addressFormInputCountry: {
        width: '100%'
    }
})

class AddressDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.address.address,
            addressType: this.props.address.addressType ? this.props.address.addressType.id : 1,
            customLabel: this.props.address.customLabel
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeCustomLabel = this.onChangeCustomLabel.bind(this);
        this.getItemsAddressType = this.getItemsAddressType.bind(this);
        this.onSelectAddressType = this.onSelectAddressType.bind(this);
    }

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });

        var address = this.getAddress();
        
        address.address[e.target.id] = e.target.value

        this.props.updateAddress(address);
    }

    onChangeCountry = (e) => {
        this.setState({ country: e });

        var address = this.getAddress();

        address.address.country = e;

        this.props.updateAddress(address);
    }
    onChangeState = (e) => {
        this.setState({ state : e});

        var address = this.getAddress();

        address.address.state = e;

        this.props.updateAddress(address);
    }

    onChangeCustomLabel = (e) => {
        this.setState({ [e.target.id]: e.target.value });

        var address = this.getAddress();

        address.customLabel = e.target.value;

        this.props.updateAddress(address);
    }
    
    onSelectAddressType(item) {
        this.setState({ addressType: item });
        
        var address = this.getAddress();

        address.address.addressType =  item;

        this.props.updateAddress(address);
    }

    getItemsAddressType() {
        var items = [];
        if (this.props.addressTypes) {
            for (var i = 0; i < this.props.addressTypes.length; i++) {
                items.push({ label: this.props.addressTypes[i].name, value: this.props.addressTypes[i].id });
            }
        }
        return items;
    }

    getAddressTypeName(id) {
        if (this.props.addressTypes) {
            for (var i = 0; i < this.props.addressTypes.length; i++) {
                if (this.props.addressTypes[i].id === id) {
                    return this.props.addressTypes[i].name;
                }
            }
        }
    }

    getAddress() {
        return {
            address: {           
                addressID: this.state.addressID,
                address1: this.state.address1,
                address2: this.state.address2,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                country: this.state.country
            },
            addressType: { id: this.state.addressType, name : this.getAddressTypeName(this.state.addressType) },
            customLabel: this.state.customLabel,
            contactID: this.props.contactID
        }
    }

    render() {
        var addressTypes = this.getItemsAddressType();
        if (!this.state.addressType) {
            this.onSelectAddressType(3);
        }
        return (
            <div className="address-detail gray-background ">
                <Row>
                    <Col sm={8}><Select
                        name="addressType"
                        options={addressTypes}
                        defaultValue={addressTypes.filter(option => option.value === (this.state.addressType ? this.state.addressType : 1))}
                        value={this.state.addressType}
                        onChange={this.onSelectAddressType} className="rs-dropdown-theme"/>
                        <Label>Address Type&nbsp;<span style={{ color: 'red' }}>*</span></Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Control autocomplete="offrandomhacktext" className={css(styles.addressFormInput)} id="address1" onChange={this.onChange} value={this.state.address1} />
                        <Label>Address Line 1</Label>
                    </Col>
                    
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Control autocomplete="offrandomhacktext" className={css(styles.addressFormInput)} id="address2" onChange={this.onChange} value={this.state.address2} />
                        <Label>Address Line 2</Label>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <Form.Control autocomplete="offrandomhacktext" className={css(styles.addressFormInput)} id="city" onChange={this.onChange} value={this.state.city} />
                        <Label>City</Label>
                    </Col>
                    <Col sm={3}>
                        <CountryDropdown
                        value={this.state.country} id="country" className={css(styles.addressFormInput, styles.addressFormInputCountry)} 
                        showDefaultOption={true}  
                        priorityOptions={["US", "CA"]}
                        onChange={this.onChangeCountry} />
                        <Label>Country&nbsp;<span style={{ color: 'red' }}>*</span></Label>    
                    </Col>
                    <Col id = "state" sm={2}> 
                        <RegionDropdown
                        country={this.state.country} className={css(styles.addressFormInput)} 
                        labelType="short"
                        valueType = "short"
                        value={this.state.state}
                        onChange={this.onChangeState} /><br/>
                        <Label>State/Province&nbsp;<span style={{ color: 'red' }}>*</span></Label>                    
                    </Col>
                    <Col sm={2}>
                        <Form.Control autocomplete="offrandomhacktext" className={css(styles.addressFormInput)} id="zip" onChange={this.onChange} value={this.state.zip} />
                        <Label>Zip/Postal Code</Label>
                    </Col>                    
                </Row>
                <Row>
                    <Col sm={8}>
                        <Form.Control autocomplete="offrandomhacktext" className={css(styles.addressFormInput)} id="customLabel" onChange={this.onChangeCustomLabel} value={this.state.customLabel} />
                        <Label>Custom Label</Label>
                        <span style={{ float: 'right', color: 'red' }}>* Required Field</span>
                    </Col>
                </Row>
            </div>
                );
    }
    
}
    
function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        addressTypes: state.sysReducer.addressTypes
    }
}

export default connect(mapStateToProps, { showAddressForm, updateAddress })(AddressDetail);
