import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { showEditForm } from '../../../hooks/store/actions/contactActions.js';
import { monthNames, getClientStatus } from '../../../hooks/store/actionConsts.js';

class ContactRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
        };

    }

    editContact(contact) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.props.showEditForm(contact);
    }

    formatDate(d) {
        if (d && d.length >= 10) {
            var m = Number(d.substring(5, 7));
            if (d.substring(0,4) == '2099') {
                return 'Ongoing'
            }
            else {
            return monthNames[m-1] + " " + d.substring(8, 10) + ", " + d.substring(0, 4);
            }
        }
        else
            return "";
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '')
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
      }

    render() {
        return (
            this.props.contacts.map(
                (j, r) =>
            
                    <Row className={r % 2 === 0 ? "grid-row row-white contactRowMobile" : "grid-row row-gray contactRowMobile"} key={j.contactID}>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={1}><span className="graphTextFront">{getClientStatus(j)}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={2}><span className="graphTextFront">{j.firstName}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={2}><span className="graphTextFront">{j.lastName}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={2} sm={1}><span className="graphTextFront">{this.formatPhoneNumber(j.mobilePhone)}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={2} sm={2}><a className="logoURLText" href={`mailto:${j.email}`} style={{zIndex: '5'}}>{j.email}</a><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={1}><span className="graphTextFront">{j.contactType.name}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={1}><span className="graphTextFront">{j.paymentStatus}</span><div style={{position: 'absolute', width: '100%', right: '0', height: '78%', borderRight: '1px solid black'}}></div><div className="highlightCell"></div></Col>
                        <Col style={{display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden'}} xs={1} sm={1}><span className="graphTextFront">{this.formatDate(j.expirationDate)}</span><div className="highlightCell"></div></Col>
                        <Col style={{ display: 'flex', alignItems: 'center', zIndex: '0', overflow: 'hidden' }} xs={1} sm={1}>
                            <Button style={{ marginTop: '7px', marginBottom: '7px', paddingTop: '7px' }} className="button-gridrow-invdetails payorrefundActions2" onClick={() => (this.editContact(j))} href="#">VIEW/EDIT</Button>
                        </Col>
                    </Row>
            )
        );
    }
}

function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user
    }
}

export default connect(mapStateToProps, { showEditForm})(ContactRow);
