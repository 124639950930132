import axios from 'axios';

import { libraryActionTypes, loginActionTypes, pending_function, rejected_function, fulfilled_function, serverEnvironment } from '../actionConsts';

export var axiosConfig = {
    headers: { 'token': '', 'refUrl': document.location.host }
};


export function getLibraryData(token) {
    const { GET_LIBRARY_DATA } = libraryActionTypes;
    const { LOGOUT } = loginActionTypes;
    return function (dispatch, getState) {
        dispatch(pending_function(GET_LIBRARY_DATA));
        var url = serverEnvironment.API_URL + '/api/library/data';
        axiosConfig.headers.token = token;

        axios
            .get(url, axiosConfig)
            .then((res) => {
                dispatch(fulfilled_function(GET_LIBRARY_DATA, res));
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch(fulfilled_function(LOGOUT), err);
                }
                dispatch(rejected_function(GET_LIBRARY_DATA, err));
            });
    }
}

export function downloadLibraryItem(token, id) {
        var url = serverEnvironment.API_URL + '/api/library/download/'+id;
        axiosConfig.headers.token = token;
        axiosConfig.responseType = 'blob';

       return axios
            .post(url, {}, axiosConfig);
}

export function updateLibraryData(data) {
    const { UPDATE_OPEN_ITEM } = libraryActionTypes;
    return function (dispatch) { 
        dispatch(fulfilled_function(UPDATE_OPEN_ITEM, data)); 
    }
}

export function updateLoading(data) {
    const { UPDATE_LOADING } = libraryActionTypes;
    return function (dispatch) { 
        dispatch(fulfilled_function(UPDATE_LOADING, data)); 
    }
}

export function createAccountLink(userId, email) {
    var url = serverEnvironment.AC_BACKEND_API_URL + '/api/v1/connect/create-account-link?userId='+userId+'&email='+email;

   return axios
        .post(url, {}, axiosConfig);
}
