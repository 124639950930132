import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Label from 'react-bootstrap/FormLabel';
import BillingPlanRow from './BillingPlanRow.js'
import InvoiceRow from './InvoiceRow.js'
import InvoiceForm from './InvoiceForm.js';
import { getClientStatus } from '../../../hooks/store/actionConsts';
import { selectContact, sendUpdateCreditCardLink } from '../../../hooks/store/actions/contactActions.js';
import { sleep } from '../../../hooks/store/actionConsts';
import { getEmptyInvoice, getBillingPlans, getInvoices, showClientDetails, getClients } from '../../../hooks/store/actions/financeActions.js';

class ClientDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            includePast: true,
            includeCurrent: true,
            includeFuture: true,
            other: true,
            createInv: false
        };
        this.hideInvoice = this.hideInvoice.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.props.getBillingPlans(this.props.logintoken, this.props.contact.contactID, this.props.contact.affiliateID);
        this.props.getInvoices(this.props.logintoken, this.props.contact.contactID, this.props.contact.affiliateID);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.viewClientDetails = this.viewClientDetails.bind(this);
    }

    viewClientDetails(e) {
        e.stopPropagation();
        this.refreshClientDetails();
    }

    onClickSendStripeEmail = () => {
        this.props.sendUpdateCreditCardLink(this.props.logintoken, this.props.contact, (this.props.contact.stripeCustomerId_Canada ? 'Canada' : 'USA'));
    }

    refreshClientDetails() {
        this.props.selectContact(this.props.contact);
        sleep(50).then(() => {
            document.location = "/clientMgmt/autoload";
        })
    }

    onClickCancel() {
        this.props.getClients(this.props.logintoken, this.props.user.id);
        this.props.showClientDetails(null);
    }

    hideInvoice(bp) {
        /// Pop up form and create invoice
        this.setState({ createInv: false });
    }

    createInvoice() {
        /// Pop up form and create invoice
        var currency = "USD";
        if (this.props.contact.addresses && this.props.contact.addresses.length > 0) {
            var cntry = this.props.contact.addresses[0].address.country;
            if (cntry === "Canada") {
                currency = "CAD";
            }
        }

        this.props.getEmptyInvoice(this.props.logintoken, this.props.contact.contactID, this.props.contact.affiliateID, 5, currency);
        this.setState({ createInv: true });
    }


    render() {


        /*
         * // Create new billing plan - shell out to the ASP.NET site
         * 
         * let showIFrame = false;
                            {props.token && showIFrame && (
                            <div style={{ width: '100%', height: '100%' }}>
    
                            <Iframe url={"http://local.acadmin.coach/Default.aspx?t=" + props.token + '&u=' + props.user.email}
                                width="100%"
                                height="83%"
                                id="aspNetSite"
                                className="aspNetSite"
                                display="block"
                                position="inherit"
                                />
    
                            </div>
                        )}
    */

        return (
            <span className="contact-grid-form">
                <div className="table-responsive billingPlanHeader">
                    <div className="mt15"><span className="viewEditClientName mt-5">Client Name: &nbsp;<b>{this.props.contact.firstName + " " + this.props.contact.lastName}</b></span>
                        <Button id="clientDetailsSearchButton" style={{ marginLeft: '14px' }} className="button-gridrow clientDetailsBtn" onClick={(e) => this.viewClientDetails(e)} href="#">REFRESH CLIENT DETAILS</Button>   
                        { (this.props.contact.stripeCustomerId_USA || this.props.contact.stripeCustomerId_Canada) &&
                            <><br />&nbsp;&nbsp;&nbsp;<button className="button-gridrow-send-stripe-email" onClick={this.onClickSendStripeEmail}>SEND CLIENT CREDIT CARD UPDATE LINK</button></>
                        }
                    </div>
                    <div className="billingPlanHeaderText"><b>Billing Plans</b></div>
                </div>

                {this.props.billingPlans && (
                    <div className="table-responsive grid-billing-plan" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                        <Row className="grid-row row-header">
                            <Col className="grid-header contactGridHead" sm={2}><Label className="labelMargin">PLAN NAME</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">STATUS</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">BILLING START</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">LAST BILL ISSUED</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">CHARGE TYPE</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">NUM SESSIONS</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">CURRENCY</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">INVOICE CHARGE</Label></Col>
                            <Col className="grid-header contactGridHead" sm={1}><Label className="labelMargin">AFFILIATE RATE</Label></Col>
                            <Col className="grid-header contactGridHead" sm={2}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Col>
                        </Row>
                        {this.props.billingPlans && (
                            <BillingPlanRow billingPlans={this.props.billingPlans} />
                        )}
                    </div>
                )}
                {/* <br /><br /><hr /><br /><br /> */}

                <div className="table-responsive billingPlanHeader"><div className="invoicesHeaderText"><b>Invoices</b></div></div>

                {this.props.invoices && (
                    <div className="table-responsive grid-billing-plan" style={{ overflow: 'unset', whiteSpace: 'pre-line' }}>
                        <Row className="grid-row row-header">
                            <Col className="grid-heade contactGridHead" sm={2}><Label className="labelMargin">INVOICE NAME</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">TYPE</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">STATUS</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">INVOICE DATE</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">PMT METHOD</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">TOTAL</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">BALANCE DUE</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">DUE DATE</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">PAID ON</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}><Label className="labelMargin">LINK</Label></Col>
                            <Col className="grid-heade contactGridHead" sm={1}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Col>
                        </Row>
                        {this.props.billingPlans && (
                            <InvoiceRow invoices={this.props.invoices} currentContact={this.props.contact}/>
                        )}
                    </div>
                )}

                {this.state.createInv && (
                    <InvoiceForm invoice={this.props.emptyInvoice} onClickCancel={this.hideInvoice} hideButtons={false} />
                )}

                {this.props.sysUser.userRole === 8 && !this.state.createInv && (
                    <Button className="button-gridrow clientListBack" onClick={() => (this.createInvoice(this.props.bp))} href="#">CREATE INVOICE</Button>
                )}

                <Button className="button-gridrow clientListBack" onClick={this.onClickCancel}>BACK TO CLIENT LIST</Button>
                <br />
                <br />
            </span>
        );
    }
}


function mapStateToProps(state) {
    return {
        logintoken: state.loginReducer.loginToken,
        user: state.loginReducer.user,
        sysUser: state.loginReducer.sysUser,
        contact: state.financeReducer.showClientDetails,
        clients: state.financeReducer.clients,
        billingPlans: state.financeReducer.billingPlans,
        invoices: state.financeReducer.invoices,
        emptyInvoice: state.financeReducer.emptyInvoice

    }
}

export default connect(mapStateToProps, { getEmptyInvoice, getBillingPlans, getInvoices, showClientDetails, getClients, selectContact, sendUpdateCreditCardLink })(ClientDetails);
