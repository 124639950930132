import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import toaster from 'toasted-notes';
import CheckBox from 'react-bootstrap/FormCheckInput';
import { serverEnvironment } from '../../hooks/store/actionConsts';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import Container from 'react-bootstrap/Container';
import { getContactsByUser, saveIcon, showEditForm } from '../../hooks/store/actions/contactActions';
import { editUserProfile, cancelEditProfile, disconnectStripeConnect, getUserProfile } from '../../hooks/store/actions/loginActions';
import { sleep } from '../../hooks/store/actionConsts.js';
import { sendRequestClient } from '../../hooks/store/actions/clientActions';
import { createAccountLink, updateLoading } from '../../hooks/store/actions/libraryActions';

class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.user,
            isDirty: false,
            tempLogo: null,
            tempPic: null,
            dropdownOpen: false,
            startFlowStripeConnect: false,
            emailStripeConnect: props.email,
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onChangePic = this.onChangePic.bind(this);
        this.onCheckInclude = this.onCheckInclude.bind(this);
        this.onClickMore = this.onClickMore.bind(this);
        this.onCreateLinkAccount = this.onCreateLinkAccount.bind(this);
        this.hasFFStripeConnect = this.hasFFStripeConnect.bind(this);
        this.onDisconnectStripeConnect = this.onDisconnectStripeConnect.bind(this);
        this.onStarConnectStripeAccount = this.onStarConnectStripeAccount.bind(this);
        this.onHandlerEmailStripeConnect = this.onHandlerEmailStripeConnect.bind(this);

       console.log(this.props.newDataProfile);
    };

    onChange = (e) => {
        this.setState({ [e.target.id]: e.target.value, isDirty: true });
    }

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });
    onChangeAffiliateType = (newAffiliateFeeType) => this.setState({ affiliateFeeType: newAffiliateFeeType, isDirty: true });

    onStarConnectStripeAccount = (e) => {
        this.setState({startFlowStripeConnect: true})
    }

    onHandlerEmailStripeConnect = (e) => {
        this.setState({ emailStripeConnect : e.target.value });
    }


    onCreateLinkAccount = (e) => {
        console.log('create account link', this.state.emailStripeConnect);

        const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;

        if(!this.state.emailStripeConnect){
            toaster.notify("You need to add an email", { duration: 4000 });
            return;
        }

        if(!EMAIL_REGEX.test(this.state.emailStripeConnect)){
            toaster.notify("You need to add an valid email", { duration: 4000 });
            return;
        }

        

        this.props.updateLoading(true);
        createAccountLink(this.props.newDataProfile.userId, this.state.emailStripeConnect)
        .then((res) => {
            this.props.updateLoading(false);
            // console.log(res.data);

            if(!res.data.completed_onboarding) {

                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    href: res.data.url,
                  }).click();
            } else {
                // refresh stripe connect status
                this.props.getUserProfile(this.props.user.id);
            }

            

        }).catch(err => {
            this.props.updateLoading(false);
            if (err.response) {
                toaster.notify("Unable to create link, try again later", { duration: 4000 });
            }
        }); 
    }

    onDisconnectStripeConnect = (e) => {
        swal({
            title: "AC Admin", text: "Are you sure you want to disconnect from Stripe Connect (Your information will not be deleted, but you will not be able to use Stripe Connect to bill unless if you use another Stripe account)?", icon: "warning",
            buttons:
            {
                no: {
                    text: "No",
                    value: "no"
                },
                yes: {
                    text: "Yes",
                    value: "yes"
                }
            }
        }).then((value) => {
            switch (value) {
                case "yes":
                    console.log('disconnecting..');
                    this.props.updateLoading(true);
                    this.props.disconnectStripeConnect(this.props.newDataProfile.userId);

                    break;
                case "no":
                   break;
                default:
                   break;
            }
        });
    }

    onClick = (e) => {
        if (this.state.isDirty) {
            var email = this.state.email;
            var firstName = this.state.firstName;
            var lastName = this.state.lastName;
            var phone = this.state.phone;
            var logoURL = this.state.logoURL;
            var professionalPic = this.state.professionalPic;
            var biography = this.state.biography;
            var includeLogoURL = this.state.includeLogoURL;
            var programInformation = this.state.programInformation;
            var certsAndCredentials = this.state.certsAndCredentials;
            var affiliateFeeType = this.state.affiliateFeeType;
            var accessDocs = this.state.canAccessDocs;
            var isLive = this.state.isLive;
            var website = this.state.website;
            var coachingType = this.state.coachingType;
            var companyName = this.state.companyName;

            if ((!firstName || (firstName && firstName === ""))) {
                toaster.notify("First Name is required.", { duration: 4000 });
                return;
            }
            if ((!lastName || (lastName && lastName === ""))) {
                toaster.notify("Last Name is required.", { duration: 4000 });
                return;
            }
            if (!email || (email && email === "")) {
                toaster.notify("Email is required.", { duration: 4000 });
                return;
            }
            if ((!phone || (phone && phone === ""))) {
                toaster.notify("Primary Phone is required.", { duration: 4000 });
                return;
            }

            if (email !== this.props.user.email) {
                swal({
                    title: "Username Change", text: "Are you absolutely sure you want to change your Username? If you change this field, you will need to use this new username/email to login to the AC Admin Hub from now on. Please be sure the spelling is correct and that this is an email you have access to in case you forget your password.\n\nConfirm Change?", icon: "warning",
                    //title: "Username Change", text: "Are you absolutely sure you want to change this Affiliate Username? If you change this field, the Affiliate will need to use this new username to login to the AC Admin Hub from now on. Please be sure the spelling is correct and that this is an email that the Affiliate has access to in case they forget their password.\n\nConfirm Change?", icon: "warning",
                    buttons:
                        {
                            no: {
                                text: "No",
                                value: "no"
                            },
                            yes: {
                                text: "Yes",
                                value: "yes"
                            }
                        }
                }).then((value) => {
                    switch (value) {
                        case "yes":
                            this.props.editUserProfile(this.props.token, this.props.user, email.trim(), firstName.trim(),
                                lastName.trim(), phone, logoURL, includeLogoURL, professionalPic, biography, programInformation, certsAndCredentials,
                                affiliateFeeType, this.props.user.userRole, accessDocs, false, website, coachingType, companyName);
                            this.setState({ isDirty: false });

                            if (!isLive) {
                                // send email change profile user
                                this.sendEmailUserChangeProfile();
                            }

                            break;
                        case "no":
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.props.editUserProfile(this.props.token, this.props.user, email.trim(), firstName.trim(),
                    lastName.trim(), phone, logoURL, includeLogoURL, professionalPic, biography, programInformation, certsAndCredentials,
                    affiliateFeeType, this.props.user.userRole, accessDocs, false, website, coachingType, companyName);
                this.setState({ isDirty: false });

                if (!isLive) {
                    // send email change profile user
                    this.sendEmailUserChangeProfile();
                }
            }
        }
        else {
            toaster.notify("Not Saved - Nothing Has Been Changed", { duration: 4000 });
            this.onClickCancel();
        }
    }

    onClickMore = (e) => {
        this.props.showEditForm(this.props.userContact);
        //this.onClick();
        this.props.cancelEditProfile();
        sleep(500).then(() => { document.location = "viewContacts"; });
    }

    onClickCancel = (e) => {
        this.setState({ isDirty: false });
        this.props.cancelEditProfile();
    }

    onCheckInclude(e) {
        this.setState({ isDirty: true });
        this.setState({ 'includeLogoURL': e.target.checked });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    onChangeFile = (e) => {
        this.setState({ "fileName": e.target.files[0], isDirty: true });

        if (e.target.files[0]) {
            var fn = e.target.files[0];

            // 1. Ensure a valid file is selected
            var reader = new FileReader();
            if (fn.size) {
                if (fn.size > (1024 * 800)) {
                    swal("File is too large. Please select a file no larger than 800 KB");
                    return;
                }

                var fileName = fn.name;
                if (fileName.indexOf(".") > 0) {
                    fileName = fileName.substring(0, fileName.indexOf("."));
                }
                fileName = fileName.split(" ").join("");

                reader.onload = function (e) {

                    var contents = e.target.result;
                    this.setState({ "tempLogo": contents });
                    this.props.saveIcon(this.props.token, this.props.user.userID, fileName, contents);

                }.bind(this);
                reader.readAsDataURL(fn);
                this.setState({ logoURL: serverEnvironment.API_URL + "/image/" + String(this.props.user.userID) + "/" + fileName });
            }
        }
    }

    onChangePic = (e) => {
        this.setState({ "fileName": e.target.files[0], isDirty: true });
        if (e.target.files[0]) {
            var fn = e.target.files[0];

            // 1. Ensure a valid file is selected
            var reader = new FileReader();
            if (fn.size) {
                if (fn.size > (1024 * 800)) {
                    swal("File is too large. Please select a file no larger than 800 KB");
                    return;
                }

                var fileName = fn.name;
                if (fileName.indexOf(".") > 0) {
                    fileName = fileName.substring(0, fileName.indexOf("."));
                }
                fileName = fileName.split(" ").join("");

                reader.onload = function (e) {

                    var contents = e.target.result;
                    this.setState({ "tempPic": contents });
                    this.props.saveIcon(this.props.token, this.props.user.userID, fileName, contents);

                }.bind(this);
                reader.readAsDataURL(fn);
                this.setState({ professionalPic: serverEnvironment.API_URL + "/image/" + String(this.props.user.userID) + "/" + fileName });
            }
        }
    }

    hasFFStripeConnect = () => {
        console.log('hasFFStripeConnect', this.props.newDataProfile);
        const { featureFlags } = this.props.newDataProfile;
        if(!featureFlags || featureFlags == "")
            return false;
        const ff = featureFlags.split(",");
        console.log(ff);
        return ff.includes("stripe_connect")
    }

    sendEmailUserChangeProfile = () => {

        var email = this.state.email;
        var firstName = this.state.firstName;
        var lastName = this.state.lastName;
        var phone = this.state.phone;
        var logoURL = this.state.logoURL;
        var professionalPic = this.state.professionalPic;
        var biography = this.state.biography;
       // var includeLogoURL = this.state.includeLogoURL;
        var programInformation = this.state.programInformation;
        var certsAndCredentials = this.state.certsAndCredentials;
        var affiliateFeeType = this.state.affiliateFeeType;
       // var accessDocs = this.state.canAccessDocs;
       // var isLive = this.state.isLive;
        var website = this.state.website;
        var coachingType = this.state.coachingType;
        var companyName = this.state.companyName;

        var fullName = ` ${firstName}  ${lastName}`;
        console.log(logoURL);

        let isNullValue = function(value) {
            if (value ===  null || value == 'null' || value === '') {
                return true
            }
            return false;
        }

        professionalPic = isNullValue(professionalPic) ?  "No image" : `<a target="__blank" href="${professionalPic}">See image</a>`;
        logoURL = isNullValue(logoURL) ?  "No image" : `<a target="__blank" href="${logoURL}">See image</a>`;

        let body = ``;
        body += `<h3>This email has been sent by the user has updated his profile data and is possibly a candidate to be visible in the coach finder of the official site, this is the updated information:</h3>`;
        body += `<p> FirstName: ${firstName}</p>`;
        body += `<p> LastName: ${lastName}</p>`;
        body += `<p> Email: ${email}</p>`;
        body += `<p> Primary Phone: ${phone}</p>`;
        body += `<p> Logo: ${logoURL}</p>`;
        body += `<p> Profesional Picture: ${professionalPic}</p>`;
        body += `<p> Biography: ${biography}</p>`;
        body += `<p> CTP Program: ${programInformation}</p>`;
        body += `<p> Cert/Credentials: ${certsAndCredentials}</p>`;
        body += `<p> Website: ${isNullValue(website) ? "" : website }</p>`;
        body += `<p> Coaching Type: ${isNullValue(coachingType) ? "" : coachingType}</p>`;
        body += `<p> Company Name: ${isNullValue(companyName) ? "" : companyName }</p>`;
        body += `<p> Affiliate Fee Type: ${affiliateFeeType}</p>`;



        sendRequestClient(this.props.token, `The user ${fullName} has been updated his profile`, "help@accomplishmentcoaching.com", body)
        .then((res) => {
            console.log('ok');
        }).catch(err => {
            console.log("Unable to send request " + item.name + ", try again later");
        });
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-popup"
                user={this.props.user}
                show={this.props.show}
                onHide={this.onClickCancel}
                style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}
            >
                 {(this.props.showLoadingGif) && (<div id="loadingGif"></div>)}
                <Modal.Body className="full-screen">
                    <Container className="Modal.Body form-editcontact modal-small">
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                First Name&nbsp;<span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="firstName" placeholder="First Name" onChange={this.onChange} value={this.state.firstName} defaultValue={this.props.user.firstName} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Last Name&nbsp;<span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="lastName" placeholder="Last Name" onChange={this.onChange} value={this.state.lastName} defaultValue={this.props.user.lastName} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Username&nbsp;<span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control disabled={((this.props.user.userRole > 2 && this.props.user.userRole < 5) || (this.props.user.userRole > 7) ) ? false : true} id="email" type="email" placeholder="Username" value={this.state.email} defaultValue={this.props.user.email} onChange={this.onChange} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Primary Phone&nbsp;<span style={{ color: 'red' }}>*</span>
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="phone" type="tel" placeholder="Phone" maxLength="31" value={this.state.phone} onChange={this.onChange} defaultValue={this.props.user.phone} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="editProfileLogoRow">
                            <Form.Label column sm={2}>
                                Affiliate Logo
                            </Form.Label>
                            <Col sm={5}><Form.Control className="smallTextBox" type="text" id="logoURL" onChange={this.onChange} value={this.state.logoURL} disabled={true} /></Col>
                            <Col sm={3}>
                                <a href={this.state.tempLogo ? this.state.tempLogo : this.state.logoURL} alt="" target="blank" rel="noopener noreferrer"><img src={this.state.tempLogo ? this.state.tempLogo : this.state.logoURL} height="60" width="" alt="" /></a>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                &nbsp;
                            </Form.Label>
                            <Col sm={6}><input type="file" className="user-photo-input selectPhotoEditProfile" id="logoURLfile" onChange={this.onChangeFile} accept="image/*" defaultValue="" /></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="editProfileIncludeLogo">
                            <Col sm={2}></Col>
                            <Col sm={6} className={this.state.logoURL ? "" : "includeLogoCheckbox"}>
                            <CheckBox style={{marginLeft : "0px", fontSize: "30px"}} checked={this.state.includeLogoURL} onChange={this.onCheckInclude} /><br />
                            <Form.Label>Include Logo on Emails and Invoices</Form.Label>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Professional Picture
                            </Form.Label>
                            <Col sm={5}>
                                <Form.Control className="smallTextBox" 
                                    type="text" 
                                    id="professionalPic" 
                                    onChange={this.onChange} 
                                    value={this.state.professionalPic} />
                            </Col>
                            <Col sm={3}>
                                <a href={this.state.tempPic ? this.state.tempPic : this.state.professionalPic} 
                                    alt="" 
                                    target="blank" 
                                    rel="noopener noreferrer">
                                    <img src={this.state.tempPic ? this.state.tempPic : this.state.professionalPic} height="60" width="" alt="" />
                                </a>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                &nbsp;
                            </Form.Label>
                            <Col sm={6}>
                                <input type="file" 
                                    className="user-photo-input selectPhotoEditProfile" 
                                    id="professionalPicfile" 
                                    onChange={this.onChangePic} 
                                    accept="image/*" 
                                    defaultValue="" />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Biography
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="biography" 
                                    type="text" 
                                    placeholder="Biography" 
                                    value={this.state.biography} 
                                    onChange={this.onChange} 
                                    defaultValue={this.props.user.biography} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                CTP Program
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control disabled={true} id="programInformation" onChange={this.onChange} value={this.state.programInformation} defaultValue={this.props.user.programInformation} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Certs/Credentials
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="certsAndCredentials" onChange={this.onChange} value={this.state.certsAndCredentials} defaultValue={this.props.user.certsAndCredentials} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Website
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="website" onChange={this.onChange} value={this.state.website} defaultValue={this.props.user.website} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Coaching Type
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="coachingType" onChange={this.onChange} value={this.state.coachingType} defaultValue={this.props.user.coachingType} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Company Name
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control id="companyName" onChange={this.onChange} value={this.state.companyName} defaultValue={this.props.user.companyName} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Affiliate Fee Type
                            </Form.Label>
                            <Col sm={8}>

                                {((this.props.user.userRole > 2 && this.props.user.userRole < 5) || (this.props.user.userRole > 7)) &&
                                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} disabled={((this.props.user.userRole > 2 && this.props.user.userRole < 5) || (this.props.user.userRole > 7)) ? false : true}>
                                        <DropdownToggle className="ddStyle" caret>
                                            {this.state.affiliateFeeType ? this.state.affiliateFeeType : "Select Affiliate Fee Type"}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>Affiliate Fee Type</DropdownItem>
                                            <DropdownItem disabled>{this.state.affiliateFeeType} <i>(current selection)</i></DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem></DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA")}>Standard USA</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian")}>Standard Canadian</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate USA")}>Flat Rate USA</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate Canadian")}>Flat Rate Canadian</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA & Canadian")}>Standard USA & Canadian</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Flat Rate USA & Canadian")}>Flat Rate USA & Canadian</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard USA")}>Leader Standard USA</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard Canadian")}>Leader Standard Canadian</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA - No Fee")}>Standard USA - No Fee</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian - No Fee")}>Standard Canadian - No Fee</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard USA - No Fee")}>Leader Standard USA - No Fee</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Leader Standard Canada - No Fee")}>Leader Standard Canada - No Fee</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard Canadian & USA")}>Standard Canadian & USA</DropdownItem>
                                            <DropdownItem className="text-primary" onClick={() => this.onChangeAffiliateType("Standard USA & Canadian – No Fee")}>Standard USA & Canadian – No Fee</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                                {((this.props.user.userRole <= 2) || (this.props.user.userRole > 4 && this.props.user.userRole < 8)) &&
                                    <Form.Control id="affiliateFeeType" className="ddStyle2" disabled={true} value={this.state.affiliateFeeType} />
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} style={{ display: this.hasFFStripeConnect() ? 'default' : 'none' }}>
                            <Form.Label column sm={2}>
                                Stripe Connect
                            </Form.Label>
                            <Col sm={8}>
                                {this.props.newDataProfile.stripeConnectAccountId == null ? 
                                    (
                                        this.state.startFlowStripeConnect ? (
                                            <div>
                                                <span>Enter the email address to connect with Stripe</span> <br ></br> <input type="text" style={{width: '220px', marginRight: '15px'}} defaultValue={this.state.email} onChange={this.onHandlerEmailStripeConnect} />
                                                <button onClick={this.onCreateLinkAccount} className="button-gridrow-edit-profile">Connect</button>
                                            </div>
                                            
                                        ): (
                                            <button onClick={this.onStarConnectStripeAccount} className="button-gridrow-edit-profile">Connect now</button>
                                        )
                                        
                                    ) : 
                                    ( 
                                        <div>
                                            <h6 style={{ marginTop: '6px' }}>Connected using {this.props.newDataProfile.email} email</h6>
                                            <button onClick={this.onDisconnectStripeConnect} className="button-gridrow-edit-profile">Disconnect</button>
                                        </div>  
                                    )
                                }
                            </Col>
                        </Form.Group>        
                        <Form.Group as={Row}>
                            <center><div className="loginModalFooter">{this.props.message}</div></center>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={2} width="50px">
                                <button className="button-gridrow-new-contact" href="#" onClick={this.onClick}>SAVE</button>
                            </Col>
                            <Col sm={2} width="50px">
                                <button className="button-gridrow-new-contact" href="#" onClick={this.onClickMore}>MORE</button>
                            </Col>
                            <Col sm={2} width="50px">
                                <button className="button-gridrow-new-contact" href="#" onClick={this.onClickCancel}>CANCEL</button>
                            </Col>

                            <Col sm={2} width="50px">
                                &nbsp;
                            </Col>
                            <Col sm={4}>
                                <span style={{ color: 'red' }}>* Required Field</span>
                            </Col>

                        </Form.Group>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.loginReducer.loginToken,
        message: state.loginReducer.message,
        user: state.loginReducer.sysUser,
        show: state.loginReducer.showEditProfile,
        userContact: state.contactReducer.userContact,
        contactMessage: state.contactReducer.contactMessage,
        newDataProfile: state.loginReducer.newDataProfile,
        showLoadingGif: state.libraryReducer.showLoadingGif,
    }
}
export default connect(mapStateToProps, { getContactsByUser, editUserProfile, cancelEditProfile, saveIcon, showEditForm, 
    updateLoading, disconnectStripeConnect, getUserProfile })(EditProfile);

